<template>
  <cargill-card
    :title="chart.title"
    :subTitle="chart.subTitle"
    :filters="chart.filters"
    hasExport
    @onFilter="$emit('onFilter')"
    @onExport="$emit('onExport')"
  >
    <div>
      <high-chart
        :chartInputData="chart.chartInput"
        v-if="chart.chartInput"
        :chart="chart"
      >
      </high-chart>
    </div>
  </cargill-card>
</template>
<script>
import CargillCard from '../cargill-card/CargillCard'
import HighChart from '../charts/HighChart'

export default {
  name: 'MonthlyPlanningChartCard',
  components: { CargillCard, HighChart },
  props: {
    chart: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {},
  computed: {
    hasChartData() {
      return this.chart?.series?.length != null
    },
    hasYAxesText() {
      return (
        this.chart?.yAxesCallback != null && this.chart?.yAxesCallback != ''
      )
    }
  }
}
</script>
<style lang="scss" scoped></style>
