<template>
  <div v-if="consolidated" class="consolidated">
    <cargill-card
      :title="consolidated.title"
      class="consolidated-wrapper"
      withoutSubtitle
    >
      <div class="consolidated-flex">
        <data-box v-bind="consolidated.salesExecuted"></data-box>
        <data-box v-bind="consolidated.onTimeFull"></data-box>
        <data-box custom>
          <p>{{ consolidated.capacityUtilization.title }}</p>
          <p
            v-for="(detail, index) in consolidated.capacityUtilization.details"
            :key="'capacityUtilization-' + index"
          >
            <span class="box-caption"
              >{{ detail.value }} {{ detail.unity }}</span
            >
            <b class="detail-title">{{ detail.title }}</b>
          </p>
        </data-box>
      </div>
    </cargill-card>
  </div>
</template>
<script>
import DataBox from './DataBox.vue'
import CargillCard from '../cargill-card/CargillCard.vue'

export default {
  name: 'PerformanceDashboardConsolidated',
  components: {
    DataBox,
    CargillCard
  },
  props: {
    consolidated: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {}
}
</script>
<style lang="scss" scoped>
::v-deep .box-wrapper {
  .box-caption {
    font-size: 0.9vw;
    font-weight: bold;
    color: #24bbef;
  }
  .detail-title {
    margin-left: 10px;
  }
}
</style>
