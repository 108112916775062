<template>
  <div>
    <!-- filter -->
    <div class="dashboard-label" style="margin-bottom: 0.5rem">
      <div>
        {{ $t('application.pages.performanceDashboard.title') }}
      </div>

      <div class="filter">
        <div class="unity">
          <div>{{ $t('application.pages.performanceDashboard.plan') }}:</div>
          <span class="unity-value" v-if="selectedPlan">{{
            selectedPlan.text
          }}</span>
        </div>
        <div class="unity">
          <div>
            {{ $t('application.pages.performanceDashboard.unityMeasure') }}:
          </div>
          <span class="unity-value" v-if="unityApplied">{{
            unityApplied.text
          }}</span>
        </div>
        <span v-show="displayRange.length">
          {{ $t('application.misc.period') }}:
          {{
            filterRange.length
              ? `${displayRange[0]} ${$t('application.misc.till')} ${
                  displayRange[1]
                } `
              : ''
          }}
        </span>

        <div class="spacer">|</div>

        <bc-popover v-model="menu" placement="bottom-end" :min-width="400">
          <template v-slot:activator="{ on, attrs }">
            <bc-btn
              icon
              class="filter-icon"
              v-bind="attrs"
              v-on="on"
              :max-width="30"
              :max-height="30"
            >
              <bc-icon>fas fa-filter</bc-icon>
            </bc-btn>
          </template>

          <template v-slot:header>
            <div class="filter-wrapper">
              <div>{{ $t('application.misc.filter') }}</div>
              <div @click="clearFilter" class="clean-filter">
                {{ $t('controlTower.filters.cleanFilter') }}
              </div>
            </div>
          </template>

          <div class="global-filter-content" id="global-filter-content">
            <datepicker-range
              v-model="filterRange"
              :maxDate="maxDate"
              :minDate="minDate"
              attach="#global-filter-content"
            />

            <bc-select
              filled
              class="input-size"
              v-model="unity"
              :items="unityValues"
              item-text="text"
              item-value="value"
              :menu-props="{ auto: true }"
              :label="$t('application.pages.performanceDashboard.unityMeasure')"
              attach="#global-filter-content"
            ></bc-select>

            <bc-select
              filled
              class="input-size"
              v-model="plan"
              :items="planValues"
              item-text="text"
              item-value="value"
              :menu-props="{ auto: true }"
              :label="$t('application.pages.performanceDashboard.plan')"
              attach="#global-filter-content"
            ></bc-select>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <bc-btn @click="menu = false" color="link-gray">
                {{ $t('application.actions.cancel') }}
              </bc-btn>
              <v-btn @click="saveFilter" color="primary">
                {{ $t('application.actions.save') }}
              </v-btn>
            </v-card-actions>
          </div>
        </bc-popover>
      </div>
    </div>
    <!-- End filter -->

    <div class="dashboard-wrapper">
      <performance-dashboard-consolidated :consolidated="consolidated" />

      <div class="grid-graphs">
        <div class="full-width">
          <monthly-planning-chart-card
            :chart="demandFullfilment"
            @onFilter="filterDemandFullfilment"
            @onExport="exportDemandFullfilment"
          ></monthly-planning-chart-card>
        </div>

        <div class="half-width">
          <cargill-card
            :title="showOtif ? otifDetailsData.title : otifData.title"
            :filters="otifData.filters"
            :showBackButton="showOtif"
            hasExport
            @onFilter="filterOtif"
            @backEvent="showOtif = false"
            @onExport="exportOtif"
          >
            <div>
              <high-chart
                v-if="otifData.chartInput && !showOtif"
                :chartInputData="otifData.chartInput"
                :chart="otifData"
                @onChartDataClick="toggleOtif($event)"
              ></high-chart>

              <div v-if="showOtif">
                <high-chart
                  v-if="showOtif && otifDetailsData.chartInput"
                  :chartInputData="otifDetailsData.chartInput"
                  :chart="otifDetailsData"
                  :isStacked="false"
                ></high-chart>
              </div>
            </div>
          </cargill-card>

          <monthly-planning-chart-card
            :chart="capacityUtilization"
            @onFilter="filterCapacityUtilization"
            @onExport="exportCapacityUtilization"
          ></monthly-planning-chart-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CargillCard from '../components/cargill-card/CargillCard'
import MonthlyPlanningChartCard from '../components/monthly-planning-chart-card/MonthlyPlanningChartCard'
import PerformanceDashboardConsolidated from '../components/data-box/PerformanceDashboardConsolidated.vue'
import moment from 'moment'
import DatepickerRange from '../components/date-picker/DatepickerRange'
import service from '../api/performanceService.js'
import HighChart from '../components/charts/HighChart'
const { getLanguage } = useUserSettings()
import { dashboardUtils, useUserSettings } from '@cargill/shared'
import { mapState } from 'pinia'

export default {
  name: 'PerformanceDashboard',
  components: {
    DatepickerRange,
    MonthlyPlanningChartCard,
    PerformanceDashboardConsolidated,
    CargillCard,
    HighChart
  },
  data() {
    return {
      formatInt: (value) => `${value.toFixed(0)}`,
      formatIntPercentage: (value) => `${value.toFixed(0)}%`,
      formatFixed1: (value) => `${value.toFixed(1)}`,
      yAxesTextUnit: '',
      yAxesTextCurrencyPerUnit: '',
      unity: dashboardUtils.getUnityTonne(),
      unityApplied: dashboardUtils.getUnityTonne(),
      unityValues: dashboardUtils.getUnities(),
      plan: dashboardUtils.getPerformancePlans()[0],
      planValues: dashboardUtils.getPerformancePlans(),
      selectedPlan: dashboardUtils.getPerformancePlans()[0],
      maxDate: null,
      minDate: null,
      filter: false,
      filterRange: [],
      lastFilterRange: [],
      displayRange: [],
      menu: false,
      consolidated: null,
      demandFullfilment: {},
      capacityUtilization: {},
      otifData: {},
      otifDetailsData: {},
      scenario: null,
      processError: true,
      showOtif: false
    }
  },
  async mounted() {
    this.initFilterRange()
    this.setDisplayRange()
    await this.filterAll()
  },
  methods: {
    initFilterRange() {
      dashboardUtils.initFilterRangePerformance(
        this.plan.value,
        this.filterRange,
        this.lastFilterRange
      )
    },
    clearFilter() {
      this.initFilterRange()
      this.unity = dashboardUtils.getUnityTonne()
      this.plan = dashboardUtils.getPerformancePlans()[0]
    },
    getTranslate() {
      return (key) => this.$t(key)
    },
    getBaseFilter() {
      return {
        startDate: this.filterRange[0],
        endDate: this.filterRange[1],
        unity: this.unityApplied.value,
        plan: this.plan.value,
        processError: this.processError
      }
    },
    async filterConsolidated() {
      const consolidated = await service.getConsolidated(this.getBaseFilter())
      this.consolidated = consolidated
    },
    async filterDemandFullfilment() {
      const chart = await service.getDemandFullfilmentChart(
        this.demandFullfilment,
        this.getBaseFilter()
      )
      chart.yAxesText = this.yAxesTextUnit
      chart.yAxesCallback = this.formatInt
      this.demandFullfilment = chart
    },
    async exportDemandFullfilment() {
      await service.getDemandFullfilmentExcel(
        this.demandFullfilment,
        this.getBaseFilter()
      )
    },
    async filterOtif() {
      const chart = await service.getOtifChart(
        this.otifData,
        this.getBaseFilter()
      )
      chart.yAxesText = this.yAxesTextUnit
      chart.yAxesCallback = this.formatInt
      this.otifData = chart
    },
    async exportOtif() {
      await service.getOtifExcel(this.otifData, this.getBaseFilter())
    },
    toggleOtif(_event) {
      if (_event) {
        this.fetchOtif(_event)
      }
    },
    async fetchOtif(event) {
      if (this.showOtif) {
        this.otifDetailsData = {}
      } else {
        this.filterOtifByDate(event)
      }
      this.showOtif = !this.showOtif
    },
    async filterOtifByDate(event) {
      const barIndex = event.event.point.index
      let dateLabel = this.otifData.chartInput.labels[barIndex][0]

      if (dateLabel[0] == 'S') {
        dateLabel = dashboardUtils.getDateByWeek(dateLabel)
      }

      const filter = this.getBaseFilter()
      filter.ReferenceDate = moment(
        dateLabel,
        'MMM - YY',
        getLanguage()
      ).format('yyyy-MM-DD')
      const chart = await service.getOtifDetailsChart(
        this.otifDetailsData,
        filter
      )

      chart.yAxesText = this.yAxesTextCurrencyPerUnit
      chart.yAxesCallback = this.formatFixed1
      this.otifDetailsData = chart
    },
    async filterCapacityUtilization() {
      const chart = await service.getCapacityUtilizationChart(
        this.capacityUtilization,
        this.getBaseFilter()
      )
      chart.yAxesText = this.yAxesTextUnit
      chart.yAxesCallback = this.formatInt
      this.capacityUtilization = chart
    },
    async exportCapacityUtilization() {
      await service.getCapacityUtilizationExcel(
        this.capacityUtilization,
        this.getBaseFilter()
      )
    },
    async filterAll() {
      if (!this.validateFilter()) {
        return
      }

      this.yAxesTextUnit = this.unityApplied.yAxesTextUnit
      this.yAxesTextCurrencyPerUnit = this.unityApplied.yAxesTextCurrencyPerUnit
      this.processError = false

      const results = await Promise.all(
        [
          this.filterConsolidated(),
          this.filterDemandFullfilment(),
          this.filterOtif(),
          this.filterCapacityUtilization()
        ].map((promise) => promise.catch((exception) => exception))
      )
      this.processError = true
      const allErrors = results
        .filter((result) => result instanceof Error)
        .map((e) => e.response.data.errors)
        .flat()
      dashboardUtils.processErrors(allErrors)
      this.updateFilterRange(this.filterRange)
      this.setDisplayRange()
    },
    validateFilter() {
      if (this.filterRange[1] == null) {
        this.notify.error({
          title: this.$t('controlTower.errors.requiredEndPeriod')
        })
        this.filterRange = this.lastFilterRange
        return false
      }
      if (this.filterRange[0] > this.filterRange[1]) {
        this.notify.error({
          title: this.$t('controlTower.errors.startDateLessEndDate')
        })
        this.filterRange = this.lastFilterRange
        return false
      }
      return true
    },
    updateFilterRange(filterRange) {
      this.filterRange = filterRange.map((x) =>
        this.dateFormat(x, 'YYYY-MM-DD')
      )
      this.lastFilterRange = [...this.filterRange]
    },
    setDisplayRange() {
      this.displayRange = [
        this.dateFormat(this.filterRange[0], this.$t('application.dateFormat')),
        this.dateFormat(this.filterRange[1], this.$t('application.dateFormat'))
      ]
    },
    saveFilter() {
      this.menu = false
      this.unityApplied = this.unity
      this.selectedPlan = this.plan
      if (this.showOtif) {
        this.otifDetailsData = {}
        this.showOtif = false
      }
      this.filterAll()
    },
    dateFormat(date, format) {
      return date == null ? '' : moment(date).format(format)
    }
  },
  computed: {
    ...mapState(useUserSettings, {
      selectedLanguage: (state) => state.getLanguage()
    })
  },
  watch: {
    selectedLanguage() {
      this.filterAll()
    },
    plan(plan) {
      dashboardUtils.initFilterRangePerformance(
        plan.value,
        this.filterRange,
        this.lastFilterRange
      )
      this.updateFilterRange(this.filterRange)
    }
  },
  provide() {
    return {
      validationResult: {}
    }
  }
}
</script>
<style lang="scss" scoped>
.global-filter-content {
  width: 300px;
}

.global-filter-content > .input-size {
  margin: 10px 0;
}

.spacer {
  color: #55565a;
  margin: 0 10px;
}
.filter {
  display: flex;
  grid-gap: 0 5px;
  align-items: center;
  .unity {
    display: flex;
    grid-gap: 0 5px;
    margin-right: 5px;
    .unity-value {
      font-weight: 400;
    }
  }
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;

  .clean-filter {
    color: white;
    cursor: pointer;
  }
  .clean-filter:hover {
    color: lightgrey;
  }
}
::v-deep .dashboard-wrapper {
  background: #232834 0% 0% no-repeat padding-box;
  padding: 1rem;
  margin: 1rem;
  .consolidated-wrapper {
    .consolidated-flex {
      display: flex;
      justify-content: space-around;
      padding: 10px 0px 15px 0;
    }
  }
  .box {
    max-width: 17vw;
    width: 17vw;
    gap: 0.8vw;
  }
  .grid-card {
    display: flex;
  }
  .grid-graphs {
    > div {
      margin: 10px 0;
    }
    .full-width {
      display: grid;
      grid-template-columns: 1fr;
    }
    .half-width {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
    }
    .one-third-width {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
    }
  }

  @media (max-width: 1800px) {
  }
}

::v-deep .dashboard-label {
  color: var(--bc-title-color);
  font-family: var(--bc-font-regular);
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0.4rem 1rem;
  background-color: #232834;
  margin-top: 1.3rem;
  align-items: center;
  margin: 1rem;
  i {
    cursor: pointer;
  }
  .filter-icon {
    color: #959db5;
    i {
      font-size: 1rem;
    }
  }
}
</style>
