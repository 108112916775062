<template>
  <div
    class="card-wrapper"
    :style="maxWidth ? 'max-width:' + maxWidth + 'px' : ''"
  >
    <v-card>
      <v-toolbar color="#2b323f" max-height="44px">
        <v-toolbar-title class="header-title" max-height="44px">
          <div class="back-wrapper">
            <div @click="backEvent" v-show="showBackButton" class="back-button">
              <bc-icon color="#959db5" size="16">fas fa-chevron-left</bc-icon>
            </div>
            {{ title }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div class="content-label">
          <div class="filter" v-if="filters != null && filters.length > 0">
            <bc-popover v-model="filterModel" placement="bottom-end">
              <template v-slot:activator="{ on, attrs }">
                <bc-btn
                  icon
                  class="filter-icon"
                  v-bind="attrs"
                  v-on="on"
                  :max-width="30"
                  :max-height="30"
                >
                  <bc-icon color="#959db5" size="16">fas fa-filter</bc-icon>
                </bc-btn>
              </template>

              <template v-slot:header>
                <div class="filter-wrapper">
                  <div>
                    {{ $t('application.misc.filter') }}
                  </div>

                  <div>
                    <span @click="clearFilter" class="clean-filter">{{
                      $t('controlTower.filters.cleanFilter')
                    }}</span>
                  </div>
                </div>
              </template>

              <div class="card-container" :id="id">
                <div
                  v-for="(filter, index) in filters"
                  :key="index"
                  :id="`${id}-filter-${index}`"
                  style="position: relative"
                >
                  <!-- DATE -->
                  <bc-menu
                    v-if="filter.type === 'date'"
                    v-model="filter.menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="400"
                    :attach="`#${id}-filter-${index}`"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <bc-text-field
                        class="input-size"
                        v-model="dateFormated"
                        :label="filter.label"
                        append-icon="fa-calendar-alt"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                      ></bc-text-field>
                    </template>

                    <bc-date-picker
                      v-model="dateValue"
                      @input="filter.menu = false"
                    ></bc-date-picker>
                  </bc-menu>

                  <!-- SELECT -->
                  <bc-select
                    v-if="
                      filter.type === 'select' || filter.type === 'multiSelect'
                    "
                    filled
                    class="input-size"
                    v-model="filter.model"
                    :items="filter.value"
                    item-text="text"
                    item-value="value"
                    :menu-props="{ auto: true }"
                    :label="filter.label"
                    :returnObject="false"
                    :multiple="filter.type == 'multiSelect'"
                    :attach="`#${id}-filter-${index}`"
                  ></bc-select>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <bc-btn @click="filterModel = false" color="link-gray">
                    {{ $t('application.actions.cancel') }}
                  </bc-btn>
                  <bc-btn @click="onFilter" color="primary">
                    {{ $t('application.actions.save') }}
                  </bc-btn>
                </v-card-actions>
              </div>
            </bc-popover>
          </div>
          <div class="export" v-if="hasExport">
            <bc-btn
              icon
              class="export-icon"
              @click="$emit('onExport')"
              :max-width="30"
              :max-height="30"
            >
              <bc-icon color="#959db5" size="16">fas fa-download</bc-icon>
            </bc-btn>
          </div>
        </div>
      </v-toolbar>
      <div class="content">
        <div class="subtitle" v-if="!withoutSubtitle">
          {{ subTitle }}
        </div>
        <slot></slot>
      </div>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment'
import { BcPopover, BcBtn } from '@brain/core'

var cardId = 0

export default {
  name: 'CargillCard',
  components: { BcPopover, BcBtn },
  data() {
    return {
      filterModel: false,
      dateFormat: null,
      dateValue: null,
      id: `cargill-card-${cardId++}`
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    filters: {
      type: Array,
      default: null
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    hasExport: {
      type: Boolean,
      default: false
    },
    withoutSubtitle: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: null
    }
  },
  mounted() {
    this.dateFormat = this.$t('application.dateFormat')
  },
  computed: {
    dateFormated() {
      if (this.dateValue != null)
        return moment(this.dateValue).format(this.dateFormat)
      return null
    }
  },
  methods: {
    onFilter() {
      const filterApplied = {}
      this.filters?.forEach((filter) => {
        filter.modelApplied = filter.model
        filterApplied[filter.name] = filter.model

        if (filter.type === 'date') {
          filter.modelApplied = this.dateValue
          filterApplied[filter.name] = this.dateValue
        }
      })
      this.$emit('onFilter', filterApplied)

      this.filterModel = false
    },
    clearFilter() {
      this.filters?.forEach((filter) => {
        if (filter.type === 'multiSelect') {
          filter.model = []
        } else if (filter.type === 'date') {
          this.dateValue = null
        } else {
          filter.model = null
        }
      })
    },
    backEvent() {
      this.$emit('backEvent')
    }
  }
}
</script>
<style lang="scss" scoped>
.spacer {
  color: #55565a;
  margin: 0 10px;
}
.card-wrapper {
  width: 100%;
  .toolbar {
    height: 44px !important;
  }
  .content-label {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 44px;
  }
  .header-title {
    text-align: left;
    font: normal normal bold 14px/63px Aller;
    letter-spacing: 0px;
    color: #959db5;
    text-transform: uppercase;
    opacity: 1;
  }
  .content {
    background: #262c39;
    flex-direction: column;
  }
}
.subtitle {
  color: #959db5;
  text-transform: uppercase;
  padding: 22px 16px 0px 16px;
  font-size: 14px;
  font-weight: bold;
}
.card-container {
  width: 450px;
  div {
    margin: 10px 0;
  }
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;

  .clean-filter {
    color: white;
    cursor: pointer;
  }
  .clean-filter:hover {
    color: lightgrey;
  }
}
.back-wrapper {
  display: flex;
  .back-button {
    padding: 0 5px;
    cursor: pointer;
    margin: 0 10px 0 -5px;
  }
}

::v-deep .v-toolbar__content {
  height: 44px !important;
}
</style>
