import { dashboardUtils } from '@cargill/shared'

const baseURL = 'api/controltower/performanceDashboard'

const service = {}

service.getConsolidated = async (filter) => {
  const url = `${baseURL}/getConsolidated`
  return await dashboardUtils.filterDashboard(filter, url)
}
service.getDemandFullfilmentChart = async (oldChart, baseFilter) => {
  const url = `${baseURL}/getDemandFullfilmentChart`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}
service.getDemandFullfilmentExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/getDemandFullfilmentExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}
service.getOtifChart = async (oldChart, baseFilter) => {
  const url = `${baseURL}/getOtifChart`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}
service.getOtifExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/getOtifExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}
service.getOtifDetailsChart = async (oldChart, baseFilter) => {
  const url = `${baseURL}/getOtifDetailsChart`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}
service.getCapacityUtilizationChart = async (oldChart, baseFilter) => {
  const url = `${baseURL}/getCapacityUtilizationChart`
  return await dashboardUtils.filterChart(oldChart, baseFilter, url)
}
service.getCapacityUtilizationExcel = async (chartRef, baseFilter) => {
  const url = `${baseURL}/getCapacityUtilizationExcel`
  return await dashboardUtils.exportExcel(chartRef, baseFilter, url)
}
export default service
