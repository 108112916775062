var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-wrapper",style:(_vm.maxWidth ? 'max-width:' + _vm.maxWidth + 'px' : '')},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#2b323f","max-height":"44px"}},[_c('v-toolbar-title',{staticClass:"header-title",attrs:{"max-height":"44px"}},[_c('div',{staticClass:"back-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBackButton),expression:"showBackButton"}],staticClass:"back-button",on:{"click":_vm.backEvent}},[_c('bc-icon',{attrs:{"color":"#959db5","size":"16"}},[_vm._v("fas fa-chevron-left")])],1),_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-spacer'),_c('div',{staticClass:"content-label"},[(_vm.filters != null && _vm.filters.length > 0)?_c('div',{staticClass:"filter"},[_c('bc-popover',{attrs:{"placement":"bottom-end"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('bc-btn',_vm._g(_vm._b({staticClass:"filter-icon",attrs:{"icon":"","max-width":30,"max-height":30}},'bc-btn',attrs,false),on),[_c('bc-icon',{attrs:{"color":"#959db5","size":"16"}},[_vm._v("fas fa-filter")])],1)]}},{key:"header",fn:function(){return [_c('div',{staticClass:"filter-wrapper"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('application.misc.filter'))+" ")]),_c('div',[_c('span',{staticClass:"clean-filter",on:{"click":_vm.clearFilter}},[_vm._v(_vm._s(_vm.$t('controlTower.filters.cleanFilter')))])])])]},proxy:true}],null,false,2423419848),model:{value:(_vm.filterModel),callback:function ($$v) {_vm.filterModel=$$v},expression:"filterModel"}},[_c('div',{staticClass:"card-container",attrs:{"id":_vm.id}},[_vm._l((_vm.filters),function(filter,index){return _c('div',{key:index,staticStyle:{"position":"relative"},attrs:{"id":(_vm.id + "-filter-" + index)}},[(filter.type === 'date')?_c('bc-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"400","attach":("#" + _vm.id + "-filter-" + index)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('bc-text-field',_vm._g(_vm._b({staticClass:"input-size",attrs:{"label":filter.label,"append-icon":"fa-calendar-alt","readonly":"","filled":""},model:{value:(_vm.dateFormated),callback:function ($$v) {_vm.dateFormated=$$v},expression:"dateFormated"}},'bc-text-field',attrs,false),on))]}}],null,true),model:{value:(filter.menu),callback:function ($$v) {_vm.$set(filter, "menu", $$v)},expression:"filter.menu"}},[_c('bc-date-picker',{on:{"input":function($event){filter.menu = false}},model:{value:(_vm.dateValue),callback:function ($$v) {_vm.dateValue=$$v},expression:"dateValue"}})],1):_vm._e(),(
                    filter.type === 'select' || filter.type === 'multiSelect'
                  )?_c('bc-select',{staticClass:"input-size",attrs:{"filled":"","items":filter.value,"item-text":"text","item-value":"value","menu-props":{ auto: true },"label":filter.label,"returnObject":false,"multiple":filter.type == 'multiSelect',"attach":("#" + _vm.id + "-filter-" + index)},model:{value:(filter.model),callback:function ($$v) {_vm.$set(filter, "model", $$v)},expression:"filter.model"}}):_vm._e()],1)}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('bc-btn',{attrs:{"color":"link-gray"},on:{"click":function($event){_vm.filterModel = false}}},[_vm._v(" "+_vm._s(_vm.$t('application.actions.cancel'))+" ")]),_c('bc-btn',{attrs:{"color":"primary"},on:{"click":_vm.onFilter}},[_vm._v(" "+_vm._s(_vm.$t('application.actions.save'))+" ")])],1)],2)])],1):_vm._e(),(_vm.hasExport)?_c('div',{staticClass:"export"},[_c('bc-btn',{staticClass:"export-icon",attrs:{"icon":"","max-width":30,"max-height":30},on:{"click":function($event){return _vm.$emit('onExport')}}},[_c('bc-icon',{attrs:{"color":"#959db5","size":"16"}},[_vm._v("fas fa-download")])],1)],1):_vm._e()])],1),_c('div',{staticClass:"content"},[(!_vm.withoutSubtitle)?_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]):_vm._e(),_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }